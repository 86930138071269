import { lazy } from 'react';

import * as addTeamConstants from 'features/addTeam/constants';
import * as employeeViewConstants from 'features/employeeView/constants';
import * as hiringConstants from 'features/hiring/constants';
import * as settingsViewConstants from 'features/settings/constants';
import * as teamViewConstants from 'features/teamView/constants';
import * as timeOffConstants from 'features/timeOff/constants';
import * as timesheetsConstants from 'features/timesheets/constants';

import DismissOnlyModal from '../../../components/modals/DismissOnlyModal';
import * as constants from '../constants';

const EmployeesStep = lazy(() =>
  import(
    /* webpackChunkName: "EmployeesStep" */ '../../../features/signUp/modals/SegmentedOnboardingModals/EmployeesStep'
  )
);
const InviteTeamModal = lazy(() =>
  import(
    /* webpackChunkName: "InviteTeamModal" */ '../../../features/teamView/InviteTeamModal'
  )
);
const ReadonlyModal = lazy(() =>
  import(
    /* webpackChunkName: "ReadonlyModal" */ '../../../components/modals/ReadonlyModal'
  )
);
const ConfirmModal = lazy(() =>
  import(
    /* webpackChunkName: "ConfirmModal" */ '../../../components/modals/ConfirmModal'
  )
);
const ConfirmWithSecondaryModal = lazy(() =>
  import(
    /* webpackChunkName: "ConfirmWithSecondaryModal" */ '../../../components/modals/ConfirmWithSecondaryModal'
  )
);
const NoticeModal = lazy(() =>
  import(
    /* webpackChunkName: "NoticeModal" */ '../../../components/modals/NoticeModal'
  )
);
const ErrorModal = lazy(() =>
  import(
    /* webpackChunkName: "ErrorModal" */ '../../../components/modals/ErrorModal'
  )
);
const OldTerminateEmployeeModal = lazy(() =>
  import(
    /* webpackChunkName: "OldTerminateEmployeeModal" */ '../../../features/employeeView/OldTerminateEmployeeModal'
  )
);
const PayAnywhereWarningModal = lazy(() =>
  import(
    /* webpackChunkName: "PayAnywhereWarningModal" */ '../../../features/employeeView/PayAnywhereWarningModal'
  )
);
const EmployeeViewAddDocumentModal = lazy(() =>
  import(
    /* webpackChunkName: "EmployeeViewAddDocumentModal" */ '../../../features/modals/EmployeeViewAddDocumentModal'
  )
);
const PageTipsModal = lazy(() =>
  import(
    /* webpackChunkName: "PageTipsModal" */ '../../../components/modals/PageTipsModal'
  )
);
const AdpRunConfirmModal = lazy(() =>
  import(
    /* webpackChunkName: "AdpRunConfirmModal" */ '../../../features/settings/PartnerChooser/AdpRunConfirmModal'
  )
);
const ConnectToastModal = lazy(() =>
  import(
    /* webpackChunkName: "ConnectToastModal" */ '../../../features/settings/ConnectToastModal/ConnectToastModal'
  )
);
const DisconnectWarningModal = lazy(() =>
  import(
    /* webpackChunkName: "DisconnectWarningModal" */ '../../../features/settings/DisconnectWarningModal/DisconnectWarningModal'
  )
);
const LaborSettingsHistoryModal = lazy(() =>
  import(
    /* webpackChunkName: "LaborSettingsHistoryModal" */ '../../../features/settings/LaborSettingsHistoryModal'
  )
);
const ApplyChangesModal = lazy(() =>
  import(
    /* webpackChunkName: "ApplyChangesModal" */ '../../../features/settings/ApplyChangesModal'
  )
);
const SyncEmployeesModal = lazy(() =>
  import(
    /* webpackChunkName: "SyncEmployeesModal" */ '../../../features/addTeam/SyncEmployeesModal'
  )
);
const SyncWarningsModal = lazy(() =>
  import(
    /* webpackChunkName: "SyncWarningsModal" */ '../../../features/addTeam/SyncWarningsModal'
  )
);
const ConnectPosModal = lazy(() =>
  import(
    /* webpackChunkName: "ConnectPosModal" */ '../../../features/addTeam/ConnectPosModal'
  )
);
const BulkAddModal = lazy(() =>
  import(
    /* webpackChunkName: "BulkAddModal" */ '../../../features/addTeam/bulkAdd'
  )
);
const BulkTerminateModal = lazy(() =>
  import(
    /* webpackChunkName: "BulkTerminateModal" */ '../../../features/teamView/bulkTerminate'
  )
);
const MobileInterstitial = lazy(() =>
  import(
    /* webpackChunkName: "MobileInterstitial" */ '../../../components/modals/MobileInterstitial/MobileInterstitial'
  )
);
const OnboardingQuestionsModal = lazy(() =>
  import(
    /* webpackChunkName: "OnboardingQuestionsModal" */ '../../../features/signUp/modals/OnboardingQuestionsModal'
  )
);
const CloverOnboardingQuestionsModal = lazy(() =>
  import(
    /* webpackChunkName: "CloverOnboardingQuestionsModal" */ '../../../features/signUp/modals/CloverOnboardingQuestionsModal'
  )
);
const IconModal = lazy(() =>
  import(
    /* webpackChunkName: "IconModal" */ '../../../features/modals/IconModal'
  )
);
const InstructionVideoModal = lazy(() =>
  import(
    /* webpackChunkName: "InstructionVideoModal" */ '../../timeClock/TimeClockView/InstructionVideoModal'
  )
);
const EndOfTrialModal1 = lazy(() =>
  import(
    /* webpackChunkName: "EndOfTrialModal1" */ '../../../features/paywalls/EndOfTrialModal1'
  )
);
const EndOfTrialModal2 = lazy(() =>
  import(
    /* webpackChunkName: "EndOfTrialModal2" */ '../../../features/paywalls/EndOfTrialModal2'
  )
);
const BeforeEndOfTrialModal = lazy(() =>
  import(
    /* webpackChunkName: "BeforeEndOfTrialModal" */ '../../../features/paywalls/BeforeEndOfTrialModal'
  )
);
const EndOfTrialManagerModal = lazy(() =>
  import(
    /* webpackChunkName: "EndOfTrialManagerModal" */ '../../../features/paywalls/EndOfTrialManagerModal'
  )
);
const EndOfTrialLocationModal = lazy(() =>
  import(
    /* webpackChunkName: "EndOfTrialLocationModal" */ '../../../features/paywalls/EndOfTrialLocationModal'
  )
);
const ToastPOSConnectModal = lazy(() =>
  import(
    /* webpackChunkName: "ToastPOSConnectModal" */ '../../../features/signUp/modals/ToastPOSConnectModal'
  )
);
const RootOnboardingModal = lazy(() =>
  import(
    /* webpackChunkName: "RootOnboardingModal" */ '../../../features/signUp/modals/SegmentedOnboardingModals/RootOnboardingModal'
  )
);
const GetMobileAppModal = lazy(() =>
  import(
    /* webpackChunkName: "GetMobileAppModal" */ '../../../features/modals/GetMobileAppModal'
  )
);
const BoostUpsellModal = lazy(() =>
  import(
    /* webpackChunkName: "BoostUpsellModal" */ '../../../features/hiring/boostUpsell/BoostUpsellModal'
  )
);
const ADPInterruptModal = lazy(() =>
  import(
    /* webpackChunkName: "ADPInterruptModal" */ '../../../features/modals/ADPInterruptModal/ADPInterruptModal'
  )
);
const NewLocationModal = lazy(() =>
  import(
    /* webpackChunkName: "NewLocationModal" */ '../../../components/modals/NewLocationModal'
  )
);
const UnsupportedMultiLocationModal = lazy(() =>
  import(
    /* webpackChunkName: "UnsupportedMultiLocationModal" */ '../../../components/modals/UnsupportedMultiLocationModal'
  )
);
const AdditionalPayrollLocationModal = lazy(() =>
  import(
    /* webpackChunkName: "AdditionalPayrollLocationModal" */ '../../../features/payroll/modals/AdditionalPayrollLocationModal'
  )
);
const FailedPaymentsAutomaticallyRefundedModal = lazy(() =>
  import(
    /* webpackChunkName: "FailedPaymentsAutomaticallyRefundedModal" */ '../../../features/payroll/modals/FailedPaymentsAutomaticallyRefundedModal'
  )
);
const NewPayrollLocationSuccessModal = lazy(() =>
  import(
    /* webpackChunkName: "NewPayrollLocationSuccessModal" */ '../../../features/payroll/modals/NewPayrollLocationSuccessModal'
  )
);
const NewLocationDepartmentModal = lazy(() =>
  import(
    /* webpackChunkName: "NewLocationDepartmentModal" */ '../../../components/modals/NewLocationDepartmentModal'
  )
);
const SecondLocationTrialSettingsModal = lazy(() =>
  import(
    /* webpackChunkName: "SecondLocationTrialSettingsModal" */ '../../../components/modals/SecondLocationTrialSettingsModal'
  )
);
const EveryoneWorkingTodayEmptyModal = lazy(() =>
  import(
    /* webpackChunkName: "EveryoneWorkingTodayEmptyModal" */ '../../../components/modals/EveryoneWorkingTodayEmptyModal'
  )
);
const SecondLocationTrialUpgradeModal = lazy(() =>
  import(
    /* webpackChunkName: "SecondLocationTrialUpgradeModal" */ '../../../features/paywalls/SecondLocationTrialUpgradeModal'
  )
);
const MonetizationRetrialModal = lazy(() =>
  import(
    /* webpackChunkName: "MonetizationRetrialModal" */ '../../../features/paywalls/monetizationRetrialModal/MonetizationRetrialModal'
  )
);
const TimecardsComplianceUpsellModal = lazy(() =>
  import(
    /* webpackChunkName: "TimecardsComplianceUpsellModal" */ '../TimecardsComplianceUpsellModal/TimecardsComplianceUpsellModal'
  )
);
const RetrialHasEndedModal = lazy(() =>
  import(
    /* webpackChunkName: "RetrialHasEndedModal" */ '../../../features/retrials/RetrialHasEndedModal'
  )
);
const EmployeeInviteModal = lazy(() =>
  import(
    /* webpackChunkName: "EmployeeInviteModal" */ '../../../features/addTeam/EmployeeInviteModal'
  )
);
const DowngradeManagersModal = lazy(() =>
  import(
    /* webpackChunkName: "DowngradeManagersModal" */ '../../../features/paywalls/DowngradeManagersModal'
  )
);
const RootRetrialOnboardingModal = lazy(() =>
  import(
    /* webpackChunkName: "RootRetrialOnboardingModal" */ '../../../features/retrials/RetrialOnboardingModals/RootRetrialOnboardingModal'
  )
);
const AutoSchedulingGuidelinesModal = lazy(() =>
  import(
    /* webpackChunkName: "AutoSchedulingGuidelinesModal" */ '../../../components/modals/AutoSchedulingGuidelinesModal'
  )
);
const SharedTimeClockModal = lazy(() =>
  import(
    /* webpackChunkName: "SharedTimeClockModal" */ '../../../features/timeClock/modals/SharedTimeClockModal'
  )
);
const UserWebTimeclockModal = lazy(() =>
  import(
    /* webpackChunkName: "UserWebTimeclockModal" */ '../../../features/timeClock/modals/UserWebTimeclockModal'
  )
);
const ChooseTimeClockModal = lazy(() =>
  import(
    /* webpackChunkName: "ChooseTimeClockModal" */ '../../../features/timeClock/modals/ChooseTimeClockModal'
  )
);
const PhotosModal = lazy(() =>
  import(
    /* webpackChunkName: "PhotosModal" */ '../../timesheets/TimesheetsPage/PayPeriodReviewView/TimesheetsTable/TableRows/TableRowsGroup/PhotosModal'
  )
);
const ExitWithoutSavingModal = lazy(() =>
  import(
    /* webpackChunkName: "ExitWithoutSavingModal" */ '../../timesheets/TimesheetsPage/TimecardModal/ExitWithoutSavingModal'
  )
);
const PayBaseModal = lazy(() =>
  import(
    /* webpackChunkName: "PayBaseModal" */ '../../../features/paywalls/PayBaseModal'
  )
);
const PremiumBoostsNotAvailableModal = lazy(() =>
  import(
    /* webpackChunkName: "PremiumBoostsNotAvailableModal" */ '../../../components/modals/PremiumBoostsNotAvailableModal'
  )
);
const DownloadTimesheetsModal = lazy(() =>
  import(
    /* webpackChunkName: "DownloadTimesheetsModal" */ '../../timesheets/TimesheetsPage/PayPeriodReviewView/DownloadTimesheetsModal'
  )
);
const ExportPermissionsModal = lazy(() =>
  import(
    /* webpackChunkName: "ExportPermissionsModal" */ '../../../features/paywalls/ExportPermissionsModal'
  )
);
const ImportTimecardsLoadingModal = lazy(() =>
  import(
    /* webpackChunkName: "ImportTimecardsLoadingModal" */ '../../timesheets/TimesheetsPage/PayPeriodReviewView/TimesheetsNavigation/ImportTimecardsLoadingModal'
  )
);
const PayrollSummaryModal = lazy(() =>
  import(
    /* webpackChunkName: "PayrollSummaryModal" */ '../../timesheets/TimesheetsPage/PayPeriodReviewView/TimesheetsNavigation/PayrollSummaryModal'
  )
);
const DownloadTypeModal = lazy(() =>
  import(
    /* webpackChunkName: "DownloadTypeModal" */ '../../timesheets/PayrollSummariesView/PayrollSummaryDetailView/DownloadTypeModal'
  )
);
const PayrollCodesModal = lazy(() =>
  import(
    /* webpackChunkName: "PayrollCodesModal" */ '../../timesheets/PayrollSummariesView/PayrollSummaryDetailView/PayrollCodesModal'
  )
);
const DashboardVideoModal = lazy(() =>
  import(
    /* webpackChunkName: "DashboardVideoModal" */ '../../../features/hiring/dashboard/DashboardV1/DashboardVideoModal'
  )
);
const PartnerEndOfIntegrationModal = lazy(() =>
  import(
    /* webpackChunkName: "PartnerEndOfIntegrationModal" */ '../../../features/modals/PartnerEndOfIntegrationModal'
  )
);
const CancelInterviewConfirmationModal = lazy(() =>
  import(
    /* webpackChunkName: "CancelInterviewConfirmationModal" */ '../../../components/modals/CancelInterviewConfirmationModal'
  )
);
const MessengerConfirmModal = lazy(() =>
  import(
    /* webpackChunkName: "MessengerConfirmModal" */ '../../../components/modals/ConfirmModalAlt'
  )
);
const DownloadMessageLogsModal = lazy(() =>
  import(
    /* webpackChunkName: "DownloadMessageLogsModal" */ '../../../features/modals/DownloadMessageLogsModal'
  )
);
const I9VerificationModal = lazy(() =>
  import(
    /* webpackChunkName: "I9VerificationModal" */ '../../../features/modals/I9VerificationModal'
  )
);
const UploadDocumentModal = lazy(() =>
  import(
    /* webpackChunkName: "UploadDocumentModal" */ '../../../features/modals/UploadDocumentModal'
  )
);
const MissingContactInformationModal = lazy(() =>
  import(
    /* webpackChunkName: "MissingContactInformationModal" */ '../../../features/employeeOnboarding/documents/MissingContactInformationModal'
  )
);
const ChangeOwnershipModal = lazy(() =>
  import(
    /* webpackChunkName: "ChangeOwnershipModal" */ '../../../features/settings/BasicSettingsView/CompanySection/ChangeOwnershipModal'
  )
);
const ChangeOwnershipNonOwnerModal = lazy(() =>
  import(
    /* webpackChunkName: "ChangeOwnershipNonOwnerModal" */ '../../../features/settings/BasicSettingsView/CompanySection/ChangeOwnershipNonOwnerModal'
  )
);
const ChangeOwnershipConfirmationModal = lazy(() =>
  import(
    /* webpackChunkName: "ChangeOwnershipConfirmationModal" */ '../../../features/settings/ChangeOwnershipView/ChangeOwnershipConfirmationModal'
  )
);
const PayrollRunTimecardModal = lazy(() =>
  import(
    /* webpackChunkName: "PayrollRunTimecardModal" */ '../../../features/payroll/modals/PayrollRunTimecardModal'
  )
);
const OtherEarningsModal = lazy(() =>
  import(
    /* webpackChunkName: "OtherEarningsModal" */ '../../../features/payroll/modals/OtherEarningsModal'
  )
);
const PaystubNotesModal = lazy(() =>
  import(
    /* webpackChunkName: "PaystubNotesModal" */ '../../../features/payroll/modals/PaystubNotesModal'
  )
);
const ReopenPayrollRunModal = lazy(() =>
  import(
    /* webpackChunkName: "ReopenPayrollRunModal" */ '../../../features/payroll/modals/ReopenPayrollRunModal'
  )
);
const ReopenAutoPayrollRunModal = lazy(() =>
  import(
    /* webpackChunkName: "ReopenPayrollRunModal" */ '../../../features/payroll/modals/ReopenAutoPayrollRunModal'
  )
);
const RunLatePayrollRunModal = lazy(() =>
  import(
    /* webpackChunkName: "RunLatePayrollRunModal" */ '../../../features/payroll/modals/RunLatePayrollRunModal'
  )
);
const EditTimesheetsConfirmationExitModal = lazy(() =>
  import(
    /* webpackChunkName: "EditTimesheetsConfirmationExitModal" */ '../../../features/payroll/modals/EditTimesheetsConfirmationExitModal'
  )
);
const EditWageRatesConfirmationExitModal = lazy(() =>
  import(
    /* webpackChunkName: "EditWageRatesConfirmationExitModal" */ '../../../features/payroll/modals/EditWageRatesConfirmationExitModal'
  )
);
const PayrollTimeOffsModal = lazy(() =>
  import(
    /* webpackChunkName: "PayrollTimeOffsModal" */ '../../../features/payroll/modals/PayrollTimeOffsModal'
  )
);
const SubmissionModal = lazy(() =>
  import(
    /* webpackChunkName: "SubmissionModal" */ '../../../features/payroll/modals/SubmissionModal'
  )
);
const PayrollMissingDetailsModal = lazy(() =>
  import(
    /* webpackChunkName: "PayrollMissingDetailsModal" */ '../../../features/payroll/modals/PayrollMissingDetailsModal'
  )
);
const ContactModal = lazy(() =>
  import(
    /* webpackChunkName: "ContactModal" */ '../../../features/payroll/modals/ContactModal'
  )
);
const GenericContactUsModal = lazy(() =>
  import(
    /* webpackChunkName: "GenericContactUsModal" */ '../../../features/payroll/modals/ContactModal/GenericContactUsModal'
  )
);
const SelfSetupTimelineModal = lazy(() =>
  import(
    /* webpackChunkName: "SelfSetupTimelineModal" */ '../../../features/payroll/modals/SelfSetupTimelineModal'
  )
);
const PayrollSelfSetupDocumentsUploadModal = lazy(() =>
  import(
    /* webpackChunkName: "PayrollSelfSetupDocumentsUploadModal" */ '../../payroll/modals/PayrollSelfSetupDocumentsUploadModal/PayrollSelfSetupDocumentsUploadModal'
  )
);
const PayrollSelfSetupDocumentsUploadSuccessModal = lazy(() =>
  import(
    /* webpackChunkName: "PayrollSelfSetupDocumentsUploadModal" */ '../../payroll/modals/PayrollSelfSetupDocumentsUploadSuccessModal'
  )
);
const PayrollConnectBankAccountModal = lazy(() =>
  import(
    /* webpackChunkName: "PayrollConnectBankAccountModal" */ '../../../features/payroll/modals/PayrollConnectBankAccountModal'
  )
);
const SelfSetupFeinRejectedModal = lazy(() =>
  import(
    /* webpackChunkName: "SelfSetupFeinRejectedModal" */ '../../../features/payroll/modals/SelfSetupFeinRejectedModal'
  )
);
const LatePaydayModal = lazy(() =>
  import(
    /* webpackChunkName: "LatePaydayModal" */ '../../../features/payroll/modals/LatePaydayModal'
  )
);
const InProductLeadModal = lazy(() =>
  import(
    /* webpackChunkName: "InProductLeadModal" */ '../../../features/payroll/modals/InProductLeadModal'
  )
);
const CheckComponentWithSignerTitleModal = lazy(() =>
  import(
    /* webpackChunkName: "CheckComponentWithSignerTitleModal" */ '../../../features/payroll/modals/CheckComponentWithSignerTitleModal'
  )
);
const FirstPayrollRunModal = lazy(() =>
  import(
    /* webpackChunkName: "FirstPayrollRunModal" */ '../../../features/payroll/modals/FirstPayrollRunModal'
  )
);
const PayrollCommitmentModal = lazy(() =>
  import(
    /* webpackChunkName: "PayrollCommitmentModal" */ '../../../features/payroll/modals/PayrollCommitmentModal'
  )
);
const DocumentUploadModal = lazy(() =>
  import(
    /* webpackChunkName: "DocumentUploadModal" */ '../../../features/payroll/modals/DocumentUploadModal'
  )
);
const RequestTaxWithholdingModal = lazy(() =>
  import(
    /* webpackChunkName: "RequestTaxWithholdingModal" */ '../../../features/payroll/modals/RequestTaxWithholdingModal/RequestTaxWithholdingModal'
  )
);
const ArchiveApplicantsModal = lazy(() =>
  import(
    /* webpackChunkName: "ArchiveApplicantsModal" */ '../../../features/hiring/dashboard/ArchiveApplicantsModal'
  )
);
const DeletePolicyConfirmationModal = lazy(() =>
  import(
    /* webpackChunkName: "DeletePolicyConfirmationModal" */ '../../../features/timeOff/TimeOffDashboardView/TimeOffPoliciesWidget/DeletePolicyConfirmationModal'
  )
);
const TimeOffLocationSettingsModal = lazy(() =>
  import(
    /* webpackChunkName: "TimeOffLocationSettingsModal" */ '../../../features/timeOff/TimeOffView/TimeOffLocationSettingsModal'
  )
);
const TimeOffRequestsDetailsModal = lazy(() =>
  import(
    /* webpackChunkName: "TimeOffRequestsDetailsModal" */ '../../../features/timeOff/TimeOffDashboardView/TimeOffRequestsWidget/TimeOffRequestsDetailsModal'
  )
);
const EmployeeBalanceHistoryModal = lazy(() =>
  import(
    /* webpackChunkName: "EmployeeBalanceHistoryModal" */ '../../../features/timeOff/TimeOffDashboardView/TimeOffBalancesWidget/EmployeeBalanceHistoryModal'
  )
);
const CompletedDocumentsSettingsModal = lazy(() =>
  import(
    /* webpackChunkName: "CompletedDocumentsSettingsModal" */ '../../settings/TeamPermissionsSettingsView/CompletedDocumentsSettingsModal'
  )
);
const PayrollAdminConfirmationModal = lazy(() =>
  import(
    /* webpackChunkName: "PayrollAdminConfirmationModal" */ '../../settings/TeamPermissionsSettingsView/PayrollAdminConfirmationModal'
  )
);
const ArchiveCustomDocumentConfirmationModal = lazy(() =>
  import(
    /* webpackChunkName: "ArchiveCustomDocumentConfirmationModal" */ '../../../features/employeeOnboarding/CustomDocumentDrawer/ArchiveCustomDocumentConfirmationModal'
  )
);
const ResendDocumentsModal = lazy(() =>
  import(
    /* webpackChunkName: "ResendDocumentsModal" */ '../../../features/employeeOnboarding/documents/ResendDocumentModal'
  )
);
const Q2TierChangeModal = lazy(() =>
  import(
    /* webpackChunkName: "Q2TierChangeModal" */ '../../../features/q2/Q2TierChangeModal'
  )
);
const PreferPaperChecksConfirmationModal = lazy(() =>
  import(
    /* webpackChunkName: "PreferPaperChecksConfirmationModal" */ '../../../features/employeeOnboarding/packet/components/PreferPaperChecksConfirmationModal'
  )
);
const HrProVideoModal = lazy(() =>
  import(
    /* webpackChunkName: "HrProVideoModal" */ '../../../features/hrPro/HrProVideoModal/HrProVideoModal'
  )
);
const ChangeTierModal = lazy(() =>
  import(
    /* webpackChunkName: "ChangeTierModal" */ '../../../features/biller/ChangeTierModal'
  )
);
const AdminChangeTierModal = lazy(() =>
  import(
    /* webpackChunkName: "AdminChangeTierModal" */ '../../biller/AdminChangeTierModal'
  )
);
const StripeAddHiringBoostModal = lazy(() =>
  import(
    /* webpackChunkName: "StripeAddHiringBoostModal" */ '../../../features/biller/implementations/stripe/AddHiringBoostModal'
  )
);
const HiringDeclinedReasonModal = lazy(() =>
  import(
    /* webpackChunkName: "HiringDeclinedReasonModal" */ '../../../features/hiring/manageApplicants/ApplicantInfoAndControls/DeclinedReasonsModal'
  )
);
const NewLocationsTierModal = lazy(() =>
  import(
    /* webpackChunkName: "NewLocationsTierModal" */ 'features/biller/NewLocationsTierModal'
  )
);
const MobileChangeTierModal = lazy(() =>
  import(
    /* webpackChunkName: "MobileChangeTierModal" */ '../../../features/biller/MobileChangeTierModal/MobileChangeTierModal'
  )
);
const StripeChangePaymentMethodModal = lazy(() =>
  import(
    /* webpackChunkName: "StripeChangePaymentMethodModal" */ '../../../features/biller/implementations/stripe/ChangePaymentMethodModal'
  )
);
const BillerQ2TierChangeModal = lazy(() =>
  import(
    /* webpackChunkName: "BillerQ2TierChangeModal" */ '../../../features/biller/implementations/q2/Q2TierChangeModal'
  )
);
const JobRequestCreatedModal = lazy(() =>
  import(
    /* webpackChunkName: "JobRequestCreatedModal" */ '../../../features/biller/JobRequestCreatedModal'
  )
);
const RootMfaEnableModal = lazy(() =>
  import(
    /* webpackChunkName: "RootMfaEnableModal" */ '../../../features/modals/Mfa/MfaEnableModals/RootMfaEnableModal'
  )
);
const RootMfaEditModal = lazy(() =>
  import(
    /* webpackChunkName: "RootMfaEditModal" */ '../../../features/modals/Mfa/MfaEditModals/RootMfaEditModal'
  )
);
const RootMfaModal = lazy(() =>
  import(
    /* webpackChunkName: "RootMfaModal" */ '../../../features/modals/Mfa/MfaModals/RootMfaModal'
  )
);
const VideoModal = lazy(() =>
  import(
    /* webpackChunkName: "VideoModal" */ '../../../components/modals/VideoModal'
  )
);
const NewLocationsSuccessModal = lazy(() =>
  import(
    /* webpackChunkName: "NewLocationsSuccessModal" */ '../../../features/biller/NewLocationsSuccessModal'
  )
);
const NewLocationsModal = lazy(() =>
  import(
    /* webpackChunkName: "NewLocationsModal" */ '../../../features/biller/NewLocationsModal'
  )
);
const AiOInformationModal = lazy(() =>
  import(
    /* webpackChunkName: "AiOInformationModal" */ '../../../components/modals/AiOInformationModal'
  )
);
const TimeOffModal = lazy(() =>
  import(
    /* webpackChunkName: "TimeOffModal" */ '../../../components/modals/TimeOffModal/TimeOffModal'
  )
);
const ReadyToRunPayrollModal = lazy(() =>
  import(
    /* webpackChunkName: "ReadyToRunPayrollModal" */ '../../../features/payroll/modals/ReadyToRunPayrollModal'
  )
);
const PayrollFailedPaymentResolutionModal = lazy(() =>
  import(
    /* webpackChunkName: "PayrollFailedPaymentResolutionModal" */ '../../../features/payroll/FailedPaymentViews/PayrollFailedPaymentResolutionModal'
  )
);
const PayrollFailedPaymentWireFundsModal = lazy(() =>
  import(
    /* webpackChunkName: "PayrollFailedPaymentWireFundsModal" */ '../../../features/payroll/FailedPaymentViews/PayrollFailedPaymentWireFundsModal'
  )
);
const PayrollFailedPaymentReattemptWithdrawalModal = lazy(() =>
  import(
    /* webpackChunkName: "PayrollFailedPaymentReattemptWithdrawalModal" */ '../../../features/payroll/FailedPaymentViews/PayrollFailedPaymentReattemptWithdrawalModal'
  )
);
const ShiftConflictModal = lazy(() =>
  import(
    /* webpackChunkName: "ShiftConflictModal" */ '../../../features/scheduleBuilder/ShiftConflictModal'
  )
);
const InviteEmployeeModal = lazy(() =>
  import(
    /* webpackChunkName: "InviteEmployeeModal" */ '../../../features/modals/InviteEmployeeModal'
  )
);
const FreeTrialModal = lazy(() =>
  import(
    /* webpackChunkName: "FreeTrialModal" */ '../../../features/modals/FreeTrialModal'
  )
);
const CompletedGuideModal = lazy(() =>
  import(
    /* webpackChunkName: "CompletedGuideModal" */ '../../../features/modals/CompletedGuideModal'
  )
);
const ConfirmationModal = lazy(() =>
  import(
    /* webpackChunkName: "ConfirmationModal" */ '../../../features/modals/ConfirmationModal'
  )
);
const CarouselMediaModal = lazy(() =>
  import(
    /* webpackChunkName: "CarouselMediaModal" */ '../../../features/modals/CarouselMediaModal'
  )
);
const UpdateLocationPaymentMessageModal = lazy(() =>
  import(
    /* webpackChunkName: "UpdateLocationPaymentMessageModal" */ '../../../features/biller/implementations/stripe/UpdateLocationPaymentMessageModal'
  )
);
const InvoicedCompanyModal = lazy(() =>
  import(
    /* webpackChunkName: "InvoicedCompanyModal" */ '../../../features/biller/InvoicedCompanyModal'
  )
);
const EnforcementDialog = lazy(() =>
  import(
    /* webpackChunkName: "EnforcementDialog" */ '../../../features/enforcement/EnforcementDialog'
  )
);
const MultilocationEnforcementDialog = lazy(() =>
  import(
    /* webpackChunkName: "EnforcementDialog" */ '../../enforcement/MultilocationEnforcementDialog'
  )
);
const EndOfTrialListDialog = lazy(() =>
  import(
    /* webpackChunkName: "EndOfTrialListDialog" */ '../../paywalls/EndOfTrialListDialog'
  )
);

const TeamPermissionsDialog = lazy(() =>
  import(
    /* webpackChunkName: "TeamPermissionsDialog" */ '../../../features/paywalls/TeamPermissionsDialog'
  )
);

const PayrollRunErrorDialog = lazy(() =>
  import(
    /* webpackChunkName: "PayrollRunErrorDialog" */ '../../../features/payroll/PayrollRunView/./PayrollRunErrorDialog'
  )
);

const MobileTimeClockCapSettingsDialog = lazy(() =>
  import(
    /* webpackChunkName: "MobileTimeClockCapSettingsDialog" */ '../../enforcement/MobileTimeClockCapSettingsDialog'
  )
);

const MobileTimeClockTrialEndDialog = lazy(() =>
  import(
    /* webpackChunkName: "MobileTimeClockTrialEndDialog" */ '../../enforcement/MobileTimeClockTrialEndDialog'
  )
);

const ContractorCashTipsModal = lazy(() =>
  import(
    /* webpackChunkName: "ContractorCashTipsModal" */ '../../../features/payroll/modals/ContractorCashTipsModal'
  )
);

const AutopayrollEnabledModal = lazy(() =>
  import(
    /* webpackChunkName: "AutopayrollEnabledModal" */ '../../../features/payroll/modals/AutopayrollEnabledModal'
  )
);

const AutopayrollDisableModal = lazy(() =>
  import(
    /* webpackChunkName: "AutopayrollDisableModal" */ '../../../features/payroll/modals/AutopayrollDisableModal'
  )
);

const DeleteAutopayrollDraftModal = lazy(() =>
  import(
    /* webpackChunkName: "DeleteAutopayrollDraftModal" */ '../../../features/payroll/modals/DeleteAutopayrollDraftModal'
  )
);

const AutopayrollFiveMinuteWarningModal = lazy(() =>
  import(
    /* webpackChunkName: "AutopayrollFiveMinuteWarningModal" */ '../../../features/payroll/modals/AutopayrollFiveMinuteWarningModal'
  )
);

const PromoteTwoStepVerificationModal = lazy(() =>
  import(
    /* webpackChunkName: "PromoteTwoStepVerificationModal" */ '../../../features/payroll/modals/PromoteTwoStepVerificationModal'
  )
);
const MissedPayrollRun = lazy(() =>
  import(
    /* webpackChunkName: "MissedPayrollRun" */ '../../../features/payroll/modals/MissedPayrollRunModal'
  )
);
const QuickbooksExportModal = lazy(() =>
  import(
    /* webpackChunkName: "QuickbooksExportModal" */ '../../timesheets/PayrollSummariesView/PayrollSummaryDetailView/QuickbooksExportModal'
  )
);

const SetupPayrollPeriodModal = lazy(() =>
  import(
    /* webpackChunkName: "SetupPayrollPeriodModal" */ '../../../features/settings/SetupPayrollPeriodModal'
  )
);

const ClickToCallDialog = lazy(() =>
  import(
    /* webpackChunkName: "ClickToCallDialog" */ '../../../features/monetization/ClickToCallDialog/ClickToCallDialog'
  )
);

const StrideHealthEmployerFlowDismissDialog = lazy(() =>
  import(
    /* webpackChunkName: "StrideHealthEmployerFlowDismissDialog" */ '../../../features/monetization/StrideHealthEmployerFlowDismissDialog'
  )
);

const EndOfTrialNbkDialog = lazy(() =>
  import(
    /* webpackChunkName: "EndOfTrialNbkDialog" */ '../../paywalls/EndOfTrialNbkDialog'
  )
);

const YearToDateManualEntrySuccessModal = lazy(() =>
  import(
    /* webpackChunkName: "YearToDateManualEntrySuccessModal" */ '../../../features/payroll/modals/YearToDateManualEntrySuccessModal'
  )
);
const CloverGoToHomebaseModal = lazy(() =>
  import('../CloverGoToHomebaseModal/CloverGoToHomebaseModal')
);

const EmployeeCertificateModal = lazy(() =>
  import(
    /* webpackChunkName: "YearToDateManualEntrySuccessModal" */ '../../../features/employeeProfile/DocumentsView/CertificateFormModal'
  )
);

const MODAL_COMPONENTS = {
  [constants.ONBOARDING_EMPLOYEES_STEP_MODAL]: EmployeesStep,
  [teamViewConstants.INVITE_TEAM_MODAL_TYPE]: InviteTeamModal,
  [constants.READONLY_MODAL_TYPE]: ReadonlyModal,
  [constants.CONFIRM_MODAL_TYPE]: ConfirmModal,
  [constants.CONFIRM_WITH_SECONDARY_MODAL_TYPE]: ConfirmWithSecondaryModal,
  [constants.DISMISS_ONLY_MODAL_TYPE]: DismissOnlyModal,
  [constants.NOTICE_MODAL_TYPE]: NoticeModal,
  [constants.ERROR_MODAL_TYPE]: ErrorModal,
  [employeeViewConstants.OLD_TERMINATE_EMPLOYEE_MODAL_TYPE]:
    OldTerminateEmployeeModal,
  [employeeViewConstants.PAY_ANYWHERE_WARNING_MODAL_TYPE]:
    PayAnywhereWarningModal,
  [employeeViewConstants.ADD_DOCUMENT_MODAL_TYPE]: EmployeeViewAddDocumentModal,
  [constants.PAGE_TIPS_MODAL_TYPE]: PageTipsModal,
  [settingsViewConstants.ADP_CREDIT_TIPS_SETTINGS_MODAL_TYPE]:
    AdpRunConfirmModal,
  [settingsViewConstants.TOAST_CONNECT_MODAL]: ConnectToastModal,
  [settingsViewConstants.DISCONNECT_WARNING_MODAL]: DisconnectWarningModal,
  [settingsViewConstants.LABOR_SETTINGS_HISTORY_MODAL]:
    LaborSettingsHistoryModal,
  [settingsViewConstants.APPLY_CHANGES_MODAL_TYPE]: ApplyChangesModal,
  [addTeamConstants.SYNC_EMPLOYEES_MODAL_TYPE]: SyncEmployeesModal,
  [addTeamConstants.SYNC_WARNINGS_MODAL_TYPE]: SyncWarningsModal,
  [addTeamConstants.CONNECT_POS_MODAL_TYPE]: ConnectPosModal,
  [addTeamConstants.BULK_ADD_TEAM_MODAL]: BulkAddModal,
  [teamViewConstants.BULK_TERMINATE_MODAL_TYPE]: BulkTerminateModal,
  [constants.MOBILE_INTERSTITIAL_MODAL_TYPE]: MobileInterstitial,
  [constants.ONBOARDING_QUESTIONS_MODAL_TYPE]: OnboardingQuestionsModal,
  [constants.CLOVER_ONBOARDING_QUESTIONS_MODAL_TYPE]:
    CloverOnboardingQuestionsModal,
  [constants.ICON_MODAL_TYPE]: IconModal,
  [timesheetsConstants.INSTRUCTION_VIDEO_MODAL_TYPE]: InstructionVideoModal,
  [constants.END_OF_TRIAL_MODAL_1]: EndOfTrialModal1,
  [constants.END_OF_TRIAL_MODAL_2]: EndOfTrialModal2,
  [constants.BEFORE_END_OF_TRIAL_MODAL]: BeforeEndOfTrialModal,
  [constants.END_OF_TRIAL_MANAGER_MODAL]: EndOfTrialManagerModal,
  [constants.END_OF_TRIAL_LOCATION_MODAL]: EndOfTrialLocationModal,
  [constants.TOAST_POS_CONNECT_MODAL]: ToastPOSConnectModal,
  [constants.ROOT_ONBOARDING_MODAL]: RootOnboardingModal,
  [constants.GET_MOBILE_APP_MODAL]: GetMobileAppModal,
  [constants.HIRING_BOOST_UPSELL_MODAL_TYPE]: BoostUpsellModal,
  [constants.ADP_INTERRUPT_MODAL]: ADPInterruptModal,
  [constants.NEW_LOCATION_MODAL]: NewLocationModal,
  [constants.UNSUPPORTED_MULTI_LOCATION_MODAL]: UnsupportedMultiLocationModal,
  [constants.ADDITIONAL_PAYROLL_LOCATION_MODAL]: AdditionalPayrollLocationModal,
  [constants.FAILED_PAYMENTS_REFUNDED_MODAL]:
    FailedPaymentsAutomaticallyRefundedModal,
  [constants.PAYROLL_NEW_LOCATION_SUCCESS_MODAL]:
    NewPayrollLocationSuccessModal,
  [constants.NEW_LOCATION_DEPARTMENT_MODAL]: NewLocationDepartmentModal,
  [constants.SECOND_LOCATION_TRIAL_SETTINGS_MODAL]:
    SecondLocationTrialSettingsModal,
  [constants.MESSENGER_EVERYONE_WORKING_TODAY_EMPTY_MODAL]:
    EveryoneWorkingTodayEmptyModal,
  [constants.SECOND_LOCATION_TRIAL_UPGRADE_MODAL]:
    SecondLocationTrialUpgradeModal,
  [constants.MONETIZATION_RETRIAL_MODAL]: MonetizationRetrialModal,
  [constants.TIMECARDS_COMPLIANCE_UPSELL_MODAL]: TimecardsComplianceUpsellModal,
  [constants.RETRIAL_HAS_ENDED_MODAL]: RetrialHasEndedModal,
  [constants.ADD_EXISTING_USER_INVITE_MODAL]: EmployeeInviteModal,
  [constants.DOWNGRADE_MANAGERS_MODAL]: DowngradeManagersModal,
  [constants.ROOT_RETRIAL_ONBOARDING_MODAL]: RootRetrialOnboardingModal,
  [constants.AUTO_SCHEDULING_GUIDELINES_MODAL]: AutoSchedulingGuidelinesModal,
  [constants.SHARED_TIMECLOCK_MODAL]: SharedTimeClockModal,
  [constants.USER_WEB_TIMECLOCK_MODAL]: UserWebTimeclockModal,
  [constants.CHOOSE_TIME_CLOCK_MODAL]: ChooseTimeClockModal,
  [timesheetsConstants.PHOTOS_MODAL_TYPE]: PhotosModal,
  [constants.EXIT_WITHOUT_SAVING_MODAL_TYPE]: ExitWithoutSavingModal,
  [constants.PAY_BASE_MODAL]: PayBaseModal,
  [constants.EMPLOYEE_CERTIFICATE_MODAL]: EmployeeCertificateModal,
  [constants.PREMIUM_BOOSTS_NOT_AVAILABLE_MODAL]:
    PremiumBoostsNotAvailableModal,
  [timesheetsConstants.DOWNLOAD_TIMESHEETS_MODAL]: DownloadTimesheetsModal,
  [constants.EXPORT_PERMISSIONS_MODAL]: ExportPermissionsModal,
  [timesheetsConstants.IMPORT_TIMECARDS_LOADING_MODAL]:
    ImportTimecardsLoadingModal,
  [timesheetsConstants.PAYROLL_SUMMARY_MODAL]: PayrollSummaryModal,
  [timesheetsConstants.DOWNLOAD_TYPE_MODAL]: DownloadTypeModal,
  [timesheetsConstants.PAYROLL_CODES_MODAL]: PayrollCodesModal,
  [constants.DASHBOARD_VIDEO_MODAL]: DashboardVideoModal,
  [constants.PARTNER_END_OF_INTEGRATION_MODAL]: PartnerEndOfIntegrationModal,
  [constants.CANCEL_INTERVIEW_CONFIRMATION_MODAL]:
    CancelInterviewConfirmationModal,
  [constants.CONFIRM_MODAL_ALT_TYPE]: MessengerConfirmModal,
  [constants.DOWNLOAD_MESSAGE_LOGS_MODAL]: DownloadMessageLogsModal,
  [constants.I9_VERIFICATION_MODAL]: I9VerificationModal,
  [constants.UPLOAD_DOCUMENT_MODAL]: UploadDocumentModal,
  [constants.MISSING_CONTACT_INFORMATION_MODAL]: MissingContactInformationModal,
  [constants.CHANGE_OWNERSHIP_MODAL]: ChangeOwnershipModal,
  [constants.CHANGE_OWNERSHIP_NON_OWNER_MODAL]: ChangeOwnershipNonOwnerModal,
  [constants.CHANGE_OWNERSHIP_CONFIRMATION_MODAL]:
    ChangeOwnershipConfirmationModal,
  [constants.PAYROLL_RUN_TIMECARD_MODAL]: PayrollRunTimecardModal,
  [constants.PAYROLL_RUN_OTHER_EARNINGS_MODAL]: OtherEarningsModal,
  [constants.PAYROLL_RUN_PAYSTUB_NOTES_MODAL]: PaystubNotesModal,
  [constants.PAYROLL_REOPEN_PAYROLL_RUN_MODAL]: ReopenPayrollRunModal,
  [constants.PAYROLL_REOPEN_AUTOPAYROLL_RUN_MODAL]: ReopenAutoPayrollRunModal,
  [constants.PAYROLL_RUN_LATE_PAYROLL_RUN_MODAL]: RunLatePayrollRunModal,
  [constants.PAYROLL_EDIT_TIMESHEETS_EXIT_CONFIRMATION_MODAL]:
    EditTimesheetsConfirmationExitModal,
  [constants.PAYROLL_EDIT_WAGE_RATES_EXIT_CONFIRMATION_MODAL]:
    EditWageRatesConfirmationExitModal,
  [constants.PAYROLL_TIME_OFFS_MODAL]: PayrollTimeOffsModal,
  [constants.PAYROLL_RUN_SUBMISSION_MODAL]: SubmissionModal,
  [constants.PAYROLL_MISSING_DETAILS_MODAL]: PayrollMissingDetailsModal,
  [constants.PAYROLL_CONTACT_MODAL]: ContactModal,
  [constants.PAYROLL_CONTACT_GENERIC_MODAL]: GenericContactUsModal,
  [constants.PAYROLL_SELF_SETUP_TIMELINE_MODAL]: SelfSetupTimelineModal,
  [constants.PAYROLL_SELF_SETUP_DOCUMENTS_UPLOAD_MODAL]:
    PayrollSelfSetupDocumentsUploadModal,
  [constants.PAYROLL_SELF_SETUP_DOCUMENTS_UPLOAD_SUCCESS_MODAL]:
    PayrollSelfSetupDocumentsUploadSuccessModal,
  [constants.PAYROLL_CONNECT_BANK_ACCOUNT_MODAL]:
    PayrollConnectBankAccountModal,
  [constants.PAYROLL_SELF_SETUP_FEIN_REJECTED_MODAL]:
    SelfSetupFeinRejectedModal,
  [constants.PAYROLL_PAYDAY_MODAL]: LatePaydayModal,
  [constants.PAYROLL_IN_PRODUCT_LEAD_MODAL]: InProductLeadModal,
  [constants.PAYROLL_CHECK_COMPONENT_WITH_SIGNER_TITLE_MODAL]:
    CheckComponentWithSignerTitleModal,
  [constants.PAYROLL_FIRST_PAYROLL_RUN_MODAL]: FirstPayrollRunModal,
  [constants.PAYROLL_COMMITMENT_MODAL]: PayrollCommitmentModal,
  [constants.PAYROLL_DOCUMENT_UPLOAD_MODAL]: DocumentUploadModal,
  [constants.REQUEST_TAX_WITHHOLDING_MODAL]: RequestTaxWithholdingModal,
  [hiringConstants.ARCHIVE_APPLICANTS_MODAL]: ArchiveApplicantsModal,
  [constants.DELETE_POLICY_CONFIRMATION_MODAL]: DeletePolicyConfirmationModal,
  [timeOffConstants.TIME_OFF_LOCATION_SETTINGS_MODAL]:
    TimeOffLocationSettingsModal,
  [timeOffConstants.TIME_OFF_REQUESTS_DETAILS_MODAL]:
    TimeOffRequestsDetailsModal,
  [timeOffConstants.TIME_OFF_EMPLOYEE_BALANCE_HISTORY_MODAL]:
    EmployeeBalanceHistoryModal,
  [settingsViewConstants.COMPLETED_DOCUMENTS_SETTINGS_MODAL]:
    CompletedDocumentsSettingsModal,
  [settingsViewConstants.PAYROLL_ADMIN_CONFIRMATION_MODAL]:
    PayrollAdminConfirmationModal,
  [constants.ARCHIVE_CUSTOM_DOCUMENT_CONFIRMATION_MODAL]:
    ArchiveCustomDocumentConfirmationModal,
  [constants.RESEND_DOCUMENTS_MODAL]: ResendDocumentsModal,
  [constants.SHOW_Q2_TIER_CHANGE_MODAL]: Q2TierChangeModal,
  [constants.PREFER_PAPER_CHECKS_CONFIRMATION_MODAL]:
    PreferPaperChecksConfirmationModal,
  [constants.HR_PRO_VIDEO_MODAL]: HrProVideoModal,
  [constants.BILLER_CHANGE_TIER_MODAL]: ChangeTierModal,
  [constants.BILLER_ADMIN_CHANGE_TIER_MODAL]: AdminChangeTierModal,
  [constants.STRIPE_ADD_HIRING_BOOST_MODAL]: StripeAddHiringBoostModal,
  [constants.HIRING_DECLINE_REASON_MODAL]: HiringDeclinedReasonModal,
  [constants.BILLER_NEW_LOCATIONS_TIER_VIEW_MODAL]: NewLocationsTierModal,
  [constants.BILLER_MOBILE_CHANGE_TIER_MODAL]: MobileChangeTierModal,
  [constants.STRIPE_CHANGE_PAYMENT_METHOD_MODAL]:
    StripeChangePaymentMethodModal,
  [constants.BILLER_Q2_CHANGE_TIER_MODAL]: BillerQ2TierChangeModal,
  [constants.BILLER_JOB_REQUEST_CREATED_MODAL]: JobRequestCreatedModal,
  [constants.ROOT_MFA_ENABLE_MODAL]: RootMfaEnableModal,
  [constants.ROOT_MFA_EDIT_MODAL]: RootMfaEditModal,
  [constants.ROOT_MFA_MODAL]: RootMfaModal,
  [constants.VIDEO_MODAL]: VideoModal,
  [constants.NEW_LOCATIONS_SUCCESS_MODAL]: NewLocationsSuccessModal,
  [constants.NEW_LOCATIONS_MODAL]: NewLocationsModal,
  [constants.ALL_IN_ONE_INFORMATION_MODAL]: AiOInformationModal,
  [constants.TIME_OFF_MODAL]: TimeOffModal,
  [constants.READY_TO_RUN_PAYROLL_MODAL]: ReadyToRunPayrollModal,
  [constants.PAYROLL_FAILED_PAYMENT_RESOLUTION_MODAL]:
    PayrollFailedPaymentResolutionModal,
  [constants.PAYROLL_FAILED_PAYMENT_WIRE_FUNDS_MODAL]:
    PayrollFailedPaymentWireFundsModal,
  [constants.PAYROLL_FAILED_PAYMENT_REATTEMPT_WITHDRAWAL_MODAL]:
    PayrollFailedPaymentReattemptWithdrawalModal,
  [constants.SHIFT_CONFLICT_MODAL]: ShiftConflictModal,
  [constants.INVITE_EMPLOYEE_MODAL]: InviteEmployeeModal,
  [constants.FREE_TRIAL_MODAL]: FreeTrialModal,
  [constants.COMPLETED_GUIDE_MODAL]: CompletedGuideModal,
  [constants.CONFIRMATION_MODAL]: ConfirmationModal,
  [constants.SCHEDULE_BUILDER_CAROUSEL_DEMO_MODAL]: CarouselMediaModal,
  [constants.UPDATE_LOCATION_PAYMENT_MESSAGE_MODAL]:
    UpdateLocationPaymentMessageModal,
  [constants.INVOICED_COMPANY_MODAL]: InvoicedCompanyModal,
  [constants.ENFORCEMENT_DIALOG]: EnforcementDialog,
  [constants.MULTILOCATION_ENFORCEMENT_DIALOG]: MultilocationEnforcementDialog,
  [constants.END_OF_TRIAL_LIST_DIALOG]: EndOfTrialListDialog,
  [constants.PAYROLL_RUN_ERROR_MODAL]: PayrollRunErrorDialog,
  [constants.PAYROLL_CONTRACTOR_CASH_TIPS_MODAL]: ContractorCashTipsModal,
  [constants.AUTOPAYROLL_ENABLED_MODAL]: AutopayrollEnabledModal,
  [constants.AUTOPAYROLL_DISABLE_MODAL]: AutopayrollDisableModal,
  [constants.DELETE_AUTOPAYROLL_DRAFT_MODAL]: DeleteAutopayrollDraftModal,
  [constants.AUTOPAYROLL_FIVE_MINUTE_WARNING_MODAL]:
    AutopayrollFiveMinuteWarningModal,
  [constants.PROMOTE_TWO_STEP_VERIFICATION_MODAL]:
    PromoteTwoStepVerificationModal,
  [constants.TEAM_PERMISSIONS_DIALOG]: TeamPermissionsDialog,
  [constants.MOBILE_TIME_CLOCK_CAP_SETTINGS_DIALOG]:
    MobileTimeClockCapSettingsDialog,
  [constants.MOBILE_TIME_CLOCK_TRIAL_END_DIALOG]: MobileTimeClockTrialEndDialog,
  [constants.PAYROLL_MISSED_RUN_MODAL]: MissedPayrollRun,
  [constants.QUICKBOOKS_EXPORT_MODAL]: QuickbooksExportModal,
  [constants.SETUP_PAYROLL_PERIOD_MODAL]: SetupPayrollPeriodModal,
  [constants.CLICK_TO_CALL_DIALOG]: ClickToCallDialog,
  [constants.STRIDE_HEALTH_EMPLOYER_FLOW_DISMISS_DIALOG]:
    StrideHealthEmployerFlowDismissDialog,
  [constants.END_OF_TRIAL_NBK_DIALOG]: EndOfTrialNbkDialog,
  [constants.YEAR_TO_DATE_MANUAL_ENTRY_SUCCESS_MODAL]:
    YearToDateManualEntrySuccessModal,
  [constants.CLOVER_GO_TO_HOMEBASE_MODAL]: CloverGoToHomebaseModal,
};

export default MODAL_COMPONENTS;
